<template>
  <div>
    <div class="container">
      <p class="input-error" v-show="this.errorMessage.form">
        {{ this.errorMessage.form }}***
      </p>
      <div class="container__head">
        <h3>Please provide the Tenants Information</h3>
        <p>Please fill out the following details for accurate registration</p>
      </div>

      <div class="form">
        <div class="email-container">
          <BaseInput
            type="email"
            placeholder="example@gmail.com"
            label="Email Address"
            :value="email"
            @input="email = $event"
            :errorMessage="errorMessage.email"
            @blur="validateEmail"
          />
        </div>

        <BaseInput
          placeholder="Michael"
          label="First Name"
          :value="firstName"
          @input="firstName = $event"
          :errorMessage="errorMessage.firstName"
          @blur="validateFirstName"
        />
        <BaseInput
          label="Last Name"
          placeholder="Ndubuisi"
          :value="lastName"
          @input="lastName = $event"
          :errorMessage="errorMessage.lastName"
          @blur="validateLastName"
        />

        <BaseInput
          label="Phone number"
          placeholder="+2348 1437 434"
          :value="phone"
          type="number"
          @input="phone = $event"
          :errorMessage="errorMessage.phone"
          @blur="validatePhone"
        />

        <InputSelect
          label="Gender"
          @select-option="gender = $event"
          @blur="validateGender"
          :value="gender"
          :errorMessage="errorMessage.gender"
        >
          <option value="male">Male</option>
          <option value="female">Female</option>
        </InputSelect>

        <div class="rent-container">
          <BaseInput
            label="Rent Value"
            placeholder="350,0000"
            :value="amount"
            type="number"
            @input="amount = $event"
            :errorMessage="errorMessage.amount"
            @blur="validateAmount"
          />
        </div>

        <div class="address-container">
          <BaseInput
            label="Current address"
            placeholder="Ralph Shodeinde street"
            :value="address"
            @input="address = $event"
            :errorMessage="errorMessage.address"
            @blur="validateAddress"
          />
        </div>
      </div>
      <div class="btn-container">
        <BaseButtton @click="submitForm" :disabled="isRegisteringTenant"
          >{{ isRegisteringTenant ? "Processing..." : "Submit" }}</BaseButtton
        >
      </div>
    </div>
  </div>
</template>

<script>
import InputSelect from "../components/form/InputSelect.vue";
import BaseInput from "../components/form/BaseInput.vue";
import { registerTenant } from "../services/registerTenant";

import BaseButtton from "../components/UI/BaseButtton.vue";
// import { registerLandlordAndProperty } from "../services/promo";
export default {
  components: {
    BaseInput,
    InputSelect,
    BaseButtton
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      amount: "",
      address: "",
      age: "",
      gender: "",
      subscription: "",
      isRegisteringTenant: false,
      processing: false,
      errorMessage: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        amount: "",
        address: "",
        age: "",
        gender: "",
        form: ""
      }
    };
  },

  methods: {
    async submitForm() {
      if (this.subscription != "YES") {
        return this.$toast.error("Subscribe to add tenant");
      }
      if (this.isFormValid) {
        //     const body = {
        //       firstName: this.firstName,
        //       lastName: this.lastName
        //     };
        //     registerLandlordAndProperty(JSON.stringify(body))
        //       .then(() => {
        //         (this.errorMessage.form = ""), (this.formSubmitted = true);
        //         this.clearForm();
        //         this.$toast.success("FORM SUBMITTED SUCCESSFULLY");
        //       })
        //       .catch(err => {
        //         console.log(err);
        //       });''

        const body = {
          firstname: this.firstName,
          lastname: this.lastName,
          email: this.email,
          phone: this.phone,
          amount: this.amount,
          address: this.address,
          paymentmethod: "yearly",
          gender: this.gender
        };

        this.isRegisteringTenant = true;
        const data = await registerTenant(JSON.stringify(body));

        try {
          // console.log(data);
          this.isRegisteringTenant = false;
          if (data.error) {
            if (data.error == "email has been taken") {
              this.$toast.error("This tenant already exists");
              this.isRegisteringTenant = false;

              return;
            }

            this.$toast.error("Unable to create this tenant");
            // this.isRegisteringTenant = true;
          }
          if (data.success) {
            this.$toast.success("Form submitted Successfully");
            this.clearForm();
            // this.isRegisteringTenant = true;
          }
        } catch (err) {
          this.$toast.error("Unable to submit request. Try again");
          this.isRegisteringTenant = false;
        }
      }
    },

    clearForm() {
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.phone = "";
      this.amount = "";
      this.address = "";
      this.paymentmethod = "";
      this.gender = "";
    },

    validateFirstName() {
      if (this.firstName.trim() === "") {
        this.errorMessage.firstName = " First name required!";
        return false;
      }
      this.errorMessage.firstName = "";
      return true;
    },
    validateLastName() {
      if (this.lastName.trim() === "") {
        this.errorMessage.lastName = " Last name required!";
        return false;
      }
      this.errorMessage.lastName = "";
      return true;
    },
    validateEmail() {
      let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

      if (this.email.trim() === "") {
        this.errorMessage.email = " Email required!";
        return false;
      }

      if (!regex.test(this.email)) {
        this.errorMessage.email = "Invalid Email";
        return false;
      }

      this.errorMessage.email = "";
      return true;
    },
    validatePhone() {
      if (this.phone.trim() === "") {
        this.errorMessage.phone = " Number required!";
        return false;
      }
      if (isNaN(this.phone.trim()) || this.phone.trim().length < 11) {
        this.errorMessage.phone = " Invalid number";
        return false;
      }

      this.errorMessage.phone = "";
      return true;
    },
    validateAmount() {
      if (this.amount.trim() === "") {
        this.errorMessage.amount = " Number required!";
        return false;
      }
      if (isNaN(this.amount.trim())) {
        this.errorMessage.amount = " Invalid amount";
        return false;
      }

      this.errorMessage.amount = "";
      return true;
    },
    validateAge() {
      if (this.age.trim() === "") {
        this.errorMessage.age = " age required!";
        return false;
      }
      if (isNaN(this.age.trim())) {
        this.errorMessage.age = " Invalid age";
        return false;
      }

      this.errorMessage.age = "";
      return true;
    },
    validateAddress() {
      if (this.address.trim() === "") {
        this.errorMessage.address = " address required!";
        return false;
      }
      this.errorMessage.address = "";
      return true;
    },
    validateGender() {
      if (this.gender === "") {
        this.errorMessage.gender = " gender required!";
        return false;
      }
      this.errorMessage.gender = "";
      return true;
    }
  },
  created() {
    this.subscription = this.$store.getters.getSubscription;
  },
  mounted() {},
  watch: {},
  computed: {
    isFormValid() {
      return (
        this.validateEmail() &&
        this.validateFirstName() &&
        this.validateLastName() &&
        this.validatePhone() &&
        this.validateGender() &&
        this.validateAmount() &&
        this.validateAddress()
      );
    }
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");

.input-error {
  color: rgb(234, 0, 0);
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  padding-top: 10px;
  padding: 1rem 2rem 0 2rem;

  /* position: absolute; */
  /* bottom: -20px; */
}

.submit-btn {
  padding: 1rem 2rem;
  background-color: var(--green-color);
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 50px;
  color: white;
  margin: auto;
}

.submit-btn:disabled {
  background-color: lightgray;
  cursor: not-allowed;
}

.btn-container {
  padding: 1rem 0;
  padding: 1.5rem;
  display: flex;
  justify-content: flex-end;
}
.container__head {
  padding-top: 1.5rem;
  margin-bottom: 1rem;
}

.container__head h3 {
  font-size: clamp(18px, 4vw, 24px);
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.container__head p {
  font-size: 12px;
  font-weight: 700;
  color: #555c74;
  font-weight: 300;
}

.form-radios {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.container {
  background-color: white;
  border-radius: 10px;
  margin-top: 2rem;
  padding: 2rem;
}

.form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  gap: 1rem;
  font-family: "Lato", sans-serif;
}

.radios-label {
  line-height: 1.5;
  font-size: clamp(12px, 4vw, 1rem);
  font-weight: 700;
}

@media (min-width: 414px) {
  .form-radios {
    gap: 1.5rem;
  }
}

@media (min-width: 768px) {
  .form {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }

  .address-container,
  .email-container,
  .rent-container {
    grid-column: span 2;
  }
}

.form > * {
  align-self: center;
}

label {
  display: block;
}

p {
  margin: 0;
}
</style>
